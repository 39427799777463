import React, { useEffect } from "react";
import "./Contact.scss";
import Axios from "axios";
import { adalApiFetch } from "../../Authentication/crmAdalConfig";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useState } from "react";
import SendMail from "./SendMail/SendMail";
import Tooltip from "@material-ui/core/Tooltip";

const Contact = props => {
  const [companyName, setCompanyName] = useState("");
  const [displayCompanyName, setDisplayCompanyName] = useState("");
  const [tooltipCompanyName, setTooltipCompanyName] = useState("");

  useEffect(() => {
    if (props.contact._parentcustomerid_value !== null) {
      adalApiFetch(
        Axios.get,
        "https://advis.crm4.dynamics.com/api/data/v9.0/accounts(" +
          props.contact._parentcustomerid_value +
          ")"
      ).then(Response => {
        setCompanyName(Response.data.name);

        if (Response.data.name.length >= 33) {
          var str = Response.data.name;
          var lastIndex;
          while (str.length >= 33) {
            lastIndex = str.lastIndexOf(" ");
            str = str.substring(0, lastIndex);
          }

          str = str + "...";
          setDisplayCompanyName(str);
          setTooltipCompanyName(Response.data.name);
        } else {
          setDisplayCompanyName(Response.data.name);
        }
      });
    }
  }, [props.contact._parentcustomerid_value]);
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#005293",
        hover: "#003158"
      },
      secondary: {
        main: "#a0a0a0"
      }
    }
  });
  const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
      color: theme.palette.common.black
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 17,
      maxWidth: "none"
    }
  }));
  const useStyles = makeStyles({
    root: {
      width: 400,
      height: 400,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      paddingBottom: "15px"
    },
    button: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      width: "95%",
      "&:hover": {
        backgroundColor: theme.palette.primary.hover
      }
    },
    tableBody: {
      color: theme.palette.primary.main,
      fontSize: 15
    },
    textField: {
      paddingBottom: "10px"
    }
  });
  const classes = useStyles();
  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }
  let Username = "";
  let UserNameFontSize = "h5";
  if (props.contact.firstname !== null) {
    Username = props.contact.firstname + " ";
  }
  if (props.contact.lastname !== null) {
    Username = Username + props.contact.lastname;
  }
  if (Username.length >= 33) {
    UserNameFontSize = "h6";
  }

  return (
    <Card elevation={10} className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant={UserNameFontSize} component="h2">
          {Username}
        </Typography>
        <Table className={classes.table} aria-label="simple table">
          <TableBody className="table">
            <TableRow>
              <TableCell
                className={classes.tableBody}
                align="left"
                padding="default"
              >
                Firma:
              </TableCell>
              <BootstrapTooltip title={tooltipCompanyName}>
                <TableCell
                  className={classes.tableBody}
                  align="left"
                  padding="default"
                >
                  {displayCompanyName}
                </TableCell>
              </BootstrapTooltip>
            </TableRow>
            <TableRow>
              <TableCell
                className={classes.tableBody}
                align="left"
                padding="default"
              >
                Email:
              </TableCell>
              <TableCell
                className={classes.tableBody}
                align="left"
                padding="default"
              >
                {props.contact.emailaddress1}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableBody} align="left">
                Telefon:
              </TableCell>
              <TableCell className={classes.tableBody} align="left">
                {props.contact.telephone1}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableBody} align="left">
                Mobile:
              </TableCell>
              <TableCell className={classes.tableBody} align="left">
                {props.contact.mobilephone}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>

      <SendMail
        name={props.contact.firstname + " " + props.contact.lastname}
        company={companyName}
        email={props.contact.emailaddress1}
        telefon={props.contact.telephone1}
        userMail={props.userMail}
        mobile={props.contact.mobilephone}
      />
    </Card>
  );
};

export default Contact;
