import React, { useState, useEffect } from "react";
import "./ContactList.scss";
import Contact from "./Contact/Contact";
import Axios from "axios";
import { adalApiFetch } from "../Authentication/crmAdalConfig";
import { adalApiFetchMSGraph } from "../Authentication/msGraphAdalConfig";
import { List, AutoSizer, WindowScroller } from "react-virtualized";

const ContactList = props => {
  const [contactsSerachData, setContactsSearchData] = useState({
    contact: []
  });
  const [showContact, setShowContact] = useState(false);

  const [userData, setUserData] = useState("");
  useEffect(() => {
    let companySearchString = "";

    adalApiFetch(
      Axios.get,
      "https://advis.crm4.dynamics.com/api/data/v9.0/accounts?$select=name,accountid&$filter=contains(name,'" +
        props.searchValue.replace(/ /g, "") +
        "')"
    ).then(Response => {
      if (Response.data.value[0] !== undefined) {
        companySearchString =
          "or _parentcustomerid_value eq " + Response.data.value[0].accountid;
      }

      let string = props.searchValue;
      let sArray = string.split(" ");
      let searchArray = [];
      sArray.forEach(s => {
        if (s !== "") {
          searchArray.push(s);
        }
      });

      adalApiFetch(
        Axios.get,
        "https://advis.crm4.dynamics.com/api/data/v9.0/contacts?$select=firstname,lastname,fullname,emailaddress1,telephone1,mobilephone,_parentcustomerid_value&$filter=contains(telephone1,'" +
          props.searchValue
            .replace(/ /g, "")
            .replace("+4", "004")
            .replace(/\b00410/, "")
            .replace(/\b410/, "")
            .replace(/\b00/, "")
            .replace(/(.)(?=.)/g, "$1%25")
            .replace("+", "") +
          "') or contains(mobilephone,'" +
          props.searchValue
            .replace(/ /g, "")
            .replace("+4", "004")
            .replace(/\b00410/, "")
            .replace(/\b410/, "")
            .replace(/\b00/, "")
            .replace(/(.)(?=.)/g, "$1%25")
            .replace("+", "") +
          "') or contains(fullname,'" +
          props.searchValue.replace(/ /g, "") +
          "') or (contains(firstname,'" +
          searchArray[0] +
          "') and contains(lastname,'" +
          searchArray[1] +
          "')) or contains(fullname,'" +
          props.searchValue.replace(/ /g, "") +
          "') or (contains(firstname,'" +
          searchArray[1] +
          "') and contains(lastname,'" +
          searchArray[0] +
          "'))" +
          companySearchString
      ).then(Response => {
        console.log(Response);
        setContactsSearchData({ contact: Response.data.value });
        setShowContact(true);
      });
    });

    adalApiFetchMSGraph(Axios.get, "https://graph.microsoft.com/v1.0/me/").then(
      response => {
        setUserData(response.data.mail);
      }
    );
  }, [props.searchValue]);

  const ITEMS_COUNT = showContact ? contactsSerachData.contact.length : 0;
  const ITEM_SIZE = 500;

  return (
    <WindowScroller>
      {({ isScrolling, registerChild, scrollTop }) => (
        <AutoSizer>
          {({ width, height }) => {
            const itemsPerRow = Math.floor(width / ITEM_SIZE);
            const rowCount = Math.ceil(ITEMS_COUNT / itemsPerRow);
            return (
              <div className="ContactList">
                <div ref={registerChild}>
                  <List
                    rowCount={rowCount}
                    autoHeight
                    width={width}
                    height={height}
                    rowHeight={ITEM_SIZE}
                    rowRenderer={({ index, key, style }) => {
                      const items = [];
                      const fromIndex = index * itemsPerRow;
                      const toIndex = Math.min(
                        fromIndex + itemsPerRow,
                        ITEMS_COUNT
                      );

                      for (let i = fromIndex; i < toIndex; i++) {
                        items.push(
                          <div className="Item" key={i}>
                            <Contact
                              key={i}
                              contact={contactsSerachData.contact[i]}
                              userMail={userData}
                            />
                          </div>
                        );
                      }

                      return (
                        <div className="Row" key={key} style={style}>
                          {items}
                        </div>
                      );
                    }}
                    scrollTop={scrollTop}
                    isScrolling={isScrolling}
                  />
                </div>
              </div>
            );
          }}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};
export default ContactList;
