import React, { useState, useEffect } from "react";
import "./UserProfilePicture.scss";
import { adalApiFetchMSGraph } from "../../../Authentication/msGraphAdalConfig.jsx";
import Axios from "axios";

const UserProfilePicture = prop => {
  const [profileImage, setProfileImage] = useState();
  useEffect(() => {
    adalApiFetchMSGraph(
      Axios.get,
      "https://graph.microsoft.com/v1.0/me/photo/$value",
      {
        responseType: "blob"
      }
    )
      .then(res => {
        setProfileImage(getImageUrl(res.data));
      })
      .catch(() => setProfileImage(""));
    const getImageUrl = data => {
      const url = window.URL || window.webkitURL;
      return url.createObjectURL(data);
    };
  }, []);
  return (
    <div className="UserProfilePicture">
      <img src={profileImage} alt="" />
    </div>
  );
};
export default UserProfilePicture;
