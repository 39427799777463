import React from "react";
import "./App.scss";
import Cockpit from "../components/Cockpit/Cockpit";

const App = () => {
  return (
    <div className="App">
      <Cockpit />
    </div>
  );
};

export default App;
