import "bootstrap/dist/css/bootstrap.min.css";
import "tachyons";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./containers/App";
import { runWithAdal } from "react-adal";
import { authContext } from "../src/components/Authentication/crmAdalConfig";

const DO_NOT_LOGIN = false;
runWithAdal(
  authContext,
  () => {
    // eslint-disable-next-line
    require("../src/containers/App.jsx");
  },
  DO_NOT_LOGIN
);
ReactDOM.render(<App />, document.getElementById("root"));
