import React from "react";
import "./Cockpit.scss";
import Navigation from "./Navigation/Navigation";
import Searchbar from "./Searchbar/Searchbar";
import ContactList from "../ContactList/ContactList";
import { useState } from "react";
import { debounce } from "lodash";
const Cockpit = props => {
  const [searchData, setSearchData] = useState("");
  const searchDataChangeHandler = debounce(text => {
    setSearchData(text);
  }, 500);
  return (
    <div className="Cockpit">
      <div className="sticky">
        <Navigation />
        <div className="Search">
          <Searchbar keyUp={e => searchDataChangeHandler(e.target.value)} />
        </div>
      </div>
      <ContactList searchValue={searchData} />
    </div>
  );
};
export default Cockpit;
