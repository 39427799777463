import React from "react";
import AdvisBlumenLogo from "../../../../assets/advisLogoBlumeWeiss.png";
import "./Logo.scss";
const Logo = () => {
  return (
    <div className="Logo">
      <img src={AdvisBlumenLogo} alt="ADVIS AG" />
    </div>
  );
};
export default Logo;
