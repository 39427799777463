import React, { useState } from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";

import TextField from "@material-ui/core/TextField";
import {
  authContext,
  adalConfig
} from "../../../Authentication/msGraphAdalConfig";

const SendMail = props => {
  const [mailContent, setMailContent] = useState("");
  const [mailMessage, setMailMessage] = useState("");
  const [userMail] = useState(props.userMail);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#005293",
        hover: "#003158"
      },
      secondary: {
        main: "#a0a0a0"
      }
    }
  });
  const useStyles = makeStyles({
    button: {
      float: "right",
      marginRight: "30px",
      "&:hover": {
        color: theme.palette.primary.hover
      },
      transform: "scale(1.2)"
    },
    textField: {
      paddingBottom: "15px",
      width: "90%"
    }
  });
  const classes = useStyles();

  const saveMailContentHandler = event => {
    var mailText = [];
    mailText.push("Name: " + props.name);
    mailText.push("Firma: " + props.company);
    mailText.push("Email: " + props.email);
    mailText.push("Telefon: " + props.telefon);
    mailText.push("Mobile: " + props.mobile);
    mailText.push("Nachricht: \n");
    setMailMessage(event.target.value);
    setMailContent(mailText.join("\n") + event.target.value);
  };

  const sendMailClick = props => {
    var myHeaders = new Headers();
    return new Promise((resolve, reject) => {
      authContext.acquireToken(
        adalConfig.endpoints.api,
        (message, token, msg) => {
          if (!!token) {
            myHeaders.append("Authorization", "Bearer " + token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
              message: {
                subject: "Rückruf",
                body: {
                  contentType: "text",
                  content: mailContent
                },
                toRecipients: [{ emailAddress: { address: userMail } }]
              }
            });

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow"
            };

            fetch(
              "https://graph.microsoft.com/v1.0/me/sendMail",
              requestOptions
            )
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log("error", error));
            setMailMessage("");
            resolve();
          } else {
            // Do something with error of acquiring the token
            authContext.login();
            reject();
          }
        }
      );
    });
  };

  return (
    <>
      <TextField
        id="outlined-multiline-static"
        label="Nachricht an Support"
        multiline
        rows="2"
        variant="outlined"
        fullWidth
        className={classes.textField}
        onChange={saveMailContentHandler}
        value={mailMessage}
      />
      <br />
      <SendIcon onClick={sendMailClick} className={classes.button} />
      {/* <Button onClick={sendMailClick} className={classes.button}>
        Send
      </Button> */}
    </>
  );
};
export default SendMail;
