import React from "react";
import "./Searchbar.scss";
const Searchbar = (props) => {
  return (
    <input
      className="Searchbar center shadow-2"
      type="text"
      placeholder="Suchen..."
      onChange={props.keyUp}
      onBlur={props.keyUp}
    />
  );
};
export default Searchbar;
