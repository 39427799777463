import React from "react";
import "./Navigation.scss";
import Logo from "./Logo/Logo";
import UserProfilePicture from "./UserProfilePicture/UserProfilePicture";

const Navigation = props => {
  return (
    <div className="Navigation flex-container">
      <Logo />
      <UserProfilePicture />
    </div>
  );
};
export default Navigation;
